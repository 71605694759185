<template>
    <div class="user-caller">
        <div class="user-title">
            <i class="iconfont icon-qukuai"></i>
            <span>{{$t('userCaller.title')}}</span>
        </div>
        <div class="user-caller-list">
            <div class="user-caller-item" v-for="(item,index) in userVisitor" :key="index">
                <img @click="goUserForm(item.visitorId)" :src="$isApi.http + item.header" alt="">
                <div>
                    <div class="user-caller-content">
                        <span @click="goUserForm(item.visitorId)">{{item.nickName}}</span>
                        <div v-if="item.sex == 'women'" style="background:#FE9ED4">
                            <i class="iconfont icon-xingbienv1"></i>
                            <span>{{item.age}}</span>
                        </div>
                        <div v-else style="background:#6798FF">
                            <i class="iconfont icon-xingbienan1"></i>
                            <span>{{item.age}}</span>
                        </div>
                        <div>{{$t('userCaller.time')}}：{{$isPulic.timestampToTime(item.createDate)}}</div>
                    </div>
                    <div class="user-caller-qian">
                        <span>{{$t('userCaller.text')}}：</span>
                        <span>{{item.signature}}</span>
                    </div>
                </div>
            </div>
            <el-pagination
                class="page-size-number"
                background
                @current-change="currentChange"
                :page-size="pageSize"
                :current-page="pageNo"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            pageNo: 1,
            userVisitor: [],
            pageSize: 10,
            total: 1
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        this.vusutorLi(1)
        this.$store.state.noReadCount = 0
    },
    methods: {
        vusutorLi(pageNo){
            var json = {
                userId: JSON.parse(sessionStorage['sendForm']).userId,
                pageNo: pageNo,
                pageSize: this.pageSize,
            }
            this.$isAxios.axiosGet(this.$isApi.userVisitor,json,(res)=>{
                if(res.data.result){
                    this.total = res.data.total
                    this.userVisitor = res.data.result
                    this.$isAxios.axiosGet(this.$isApi.clearNoRead,{userId:this.sendForm.userId},(res)=>{
                        this.$store.state.noReadCount = 0
                    })
                }
            })
        },
        currentChange(e){
            this.vusutorLi(e)
        },
        goUserForm(id){
            sessionStorage['userId'] = id
            this.$nextTick(()=>{
                this.$router.push({
                    path: "/page/userXQ"
                })
            })
        }
    }
}
</script>

<style scoped>
.user-caller{
    height: max-content;
    padding: 20px 30px;
    background: #fff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
}
.user-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px
}
.user-title>i{
    font-size: 20px;
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin-right: 6px;
    color: #f00;
}
.user-caller-list{
    height: max-content;
}
.user-caller-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #eee
}
.user-caller-item:last-child{
    border: none
}
.user-caller-item>img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background: #000;
    object-fit: cover;
    flex-grow: 0;
    cursor: pointer;
}
.user-caller-item>div{
    width: calc( 100% - 80px );
    height: max-content;
}
.user-caller-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: max-content;
    margin-bottom: 8px
}
.user-caller-content>span{
    font-size: 16px;
    color: #333;
    font-weight: bold;
    cursor: pointer;
}
.user-caller-content>span:hover{
    text-decoration: underline
}
.user-caller-content>div:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 18px;
    padding: 0 6px;
    border-radius: 9px;
    overflow: hidden;
    margin-left: 10px
}
.user-caller-content>div:nth-child(2)>i{
    display: block;
    font-size: 14px;
    color: #fff;
    margin-top: 2px;
    font-weight: bold;
}
.user-caller-content>div:nth-child(2)>span{
    display: block;
    font-size: 12px;
    color: #fff;
}
.user-caller-content>div:nth-child(3){
    flex: 1;
    text-align: right;
    font-size: 12px;
    color: #999;
}
.user-caller-qian{
    font-size: 14px;
    color: #666;
    font-size: 13px;
    color: #666;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>